import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, Button, Divider, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DataGrid } from '@material-ui/data-grid';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

import Loader from '../../common/loader';
import Crud from '../../common/crud';
import NotFound from '../../common/not-found';
import ProcessingStatusLabel from '../components/processing-status-label';

import AuthContext from '../../context';
import { del, get, post, put } from '../../utils/api-services';
import helpers from '../../utils/helpers';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 20
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    button: {
        width: '100%',
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    discarted: {
        width: '100%',
        backgroundColor: '#A901DB',
        borderColor: '#A901DB',
        '&:hover': {
            backgroundColor: '#A901DB',
            borderColor: '#A901DB',
            boxShadow: 'none',
        }
    },
    inStorage: {
        width: '100%',
        backgroundColor: '#0080FF',
        borderColor: '#0080FF',
        '&:hover': {
            backgroundColor: '#0080FF',
            borderColor: '#0080FF',
            boxShadow: 'none',
        }
    },
    divider: {
        marginBottom: 24
    },
    special_button: {
        backgroundColor: '#642EFE',
        borderColor: '#642EFE',
        '&:hover': {
            backgroundColor: '#642EFE',
            borderColor: '#642EFE',
            boxShadow: 'none'
        }
    },
    tableContainer: {
        width: '100%'
    }
}));

const ProcessV2Detail = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const location = useLocation();
    const [state, setState] = useState({
        loading: true,
        code: '',
        ticket: {},
        employees: [],
        items: [],
        data: [],
        process: {
            idticket_process_v2: 0,
            date: dayjs().format('YYYY-MM-DD'),
            processed_qty: 0,
            total_qty: 0,
            pending_qty: 0,
            employee: { id: 0, name: 'Choose an employee' },
            idemployee: 0,
            item: { id: 0, name: 'Choose an item' },
            iditem: 0
        },
        permissions: {}
    });

    useEffect(() => {
        window.document.title = 'Ticket processes v2';

        validatePermissions();
    }, []);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(location.pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        const code = location.state?.code ?? '';

        const response = await get('/processV2/detail', { code });
        if (response.status === 200) {
            const newState = { code, loading: false, employees: response.employees, items: response.items };
            if (code) {
                newState.ticket = response.ticket;
                newState.data = response.data;
            }

            setState(prev => ({ ...prev, ...newState }));
        } else {
            console.log('[ProcessV2Detail] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut);
        }
    }

    const handleChange = ({ target }) => setState(prev => ({ ...prev, [target.name]: target.value }));

    const handleAutocomplete = (key, value) => setState(prev => ({ ...prev, process: { ...prev.process, [key]: value } }));

    const handleQty = ({ target }) => {
        const { product_qty } = state.ticket;

        const processed_qty = target.value;
        const total_qty = Number(processed_qty) * Number(product_qty ?? 0);

        setState(prev => ({ ...prev, process: { ...prev.process, processed_qty, total_qty } }));
    }

    const getTicket = async () => {
        const { code } = state;

        if (code.trim()) {
            showLoader(true);
            const response = await get(`/processV2/${code}`);
            if (response.status === 200) {
                setState(prev => ({ ...prev, ticket: response.ticket, data: response.data }));
            } else {
                console.log('[ProcessV2Detail] getTicket error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const validate = type => {
        const { idticket } = state.ticket;
        const { create_attr, update_attr, delete_attr } = state.permissions;
        const { idticket_process_v2, processed_qty } = state.process;

        switch (type) {
            case 'discard':
                if (!update_attr) {
                    showToast('You do not have permissions for this action', 'error', 6500);
                    return false;
                }
                if (!idticket) {
                    showToast('Search some ticket', 'error');
                    return false;
                }

                return Boolean(window.confirm(`Are you sure you want to mark this ticket as error?`));

            case 'inStorage':
                if (!update_attr) {
                    showToast('You do not have permissions for this action', 'error', 6500);
                    return false;
                }
                if (!idticket) {
                    showToast('Search some ticket', 'error');
                    return false;
                }

                return Boolean(window.confirm(`Are you sure you want to mark this ticket as in storage?`));

            case 'store':
                if (!create_attr) {
                    showToast('You do not have permissions for this action', 'error', 6500);
                    return false;
                }
                if (!idticket) {
                    showToast('Search some ticket', 'error');
                    return false;
                }
                if (Number(processed_qty) <= 0) {
                    showToast('Processed qty is required', 'error');
                    return false;
                }

                return true;

            case 'update':
                if (!update_attr) {
                    showToast('You do not have permissions for this action', 'error', 6500);
                    return false;
                }
                if (!idticket) {
                    showToast('Search some ticket', 'error');
                    return false;
                }
                const process = state.data.find(item => item.idticket_process_v2 === idticket_process_v2);
                if (!process) {
                    showToast('Select some process', 'error');
                    return false;
                }
                const before = JSON.stringify(process);
                const after = JSON.stringify(state.process);
                if (before === after) {
                    showToast('Update some field', 'error');
                    return false;
                }
                if (Number(processed_qty) <= 0) {
                    showToast('Processed qty is required', 'error');
                    return false;
                }

                return true;

            case 'delete':
                if (!delete_attr) {
                    showToast('You do not have permissions for this action', 'error', 6500);
                    return false;
                }
                if (idticket_process_v2 === 0) {
                    showToast('Select some process');
                    return false;
                }

                return Boolean(window.confirm('Are you sure you want to delete this process?'));

            case 'complete':
                if (!update_attr) {
                    showToast('You do not have permissions for this action', 'error', 6500);
                    return false;
                }
                if (!idticket) {
                    showToast('Search some ticket', 'error');
                    return false;
                }

                return window.confirm(`Are you sure you want to complete without ticket?`);
            default:
                break;
        }
    }

    const setDiscarted = async () => {
        const { idticket } = state.ticket;

        const cond = validate('discard');
        if (cond) {
            showLoader(true);
            const response = await put(`/processV2/${idticket}/discard`);
            if (response.status === 200) {
                setState(prev => ({ ...prev, data: response?.data ?? prev.data, ticket: { ...prev.ticket, processing_status: response.processingStatus } }));
                showToast(response.message);
            } else {
                console.log('[ProcessV2Detail] setDiscarted error', response);
                helpers.failRequest(response, showToast, signOut)
            }
            showLoader(false);
        }
    }

    const toggleInStorage = async () => {
        const { idticket } = state.ticket;

        const cond = validate('inStorage');
        if (cond) {
            showLoader(true);
            const response = await put(`/processV2/${idticket}/setInStorage`);
            if (response.status === 200) {
                setState(prev => ({ ...prev, data: response?.data ?? prev.data, ticket: { ...prev.ticket, processing_status: response.processingStatus } }));
                showToast(response.message);
            } else {
                console.log('[ProcessV2Detail] toggleInStorage error', response);
                helpers.failRequest(response, showToast, signOut)
            }
            showLoader(false);
        }
    }

    const store = async () => {
        const form = { ...state.process, idticket: state.ticket.idticket };

        const cond = validate('store');
        if (cond) {
            showLoader(true);
            const response = await post(`/processV2/store`, form);
            if (response.status === 200) {
                setState(prev => ({ ...prev, data: response.data ?? [], ticket: { ...prev.ticket, processing_status: response.processingStatus } }));
                showToast(response.message);
            } else {
                console.log('[ProcessV2Detail] store error', response);
                helpers.failRequest(response, showToast, signOut);
                showLoader(false);

                return false;
            }
            showLoader(false);
        }
    }

    const update = async () => {
        const { idticket_process_v2 } = state.process;

        const cond = validate('update');
        if (cond) {
            showLoader(true);
            const response = await put(`/processV2/${idticket_process_v2}/update`, state.process);
            if (response.status === 200) {
                setState(prev => ({ ...prev, data: response?.data ?? [], ticket: { ...prev.ticket, processing_status: response.processingStatus } }));
                showToast(response.message);
            } else {
                console.log('[ProcessV2Detail] update error', response);
                helpers.failRequest(response, showToast, signOut)
            }
            showLoader(false);
        }
    }

    const destroy = async () => {
        const { idticket_process_v2 } = state.process;

        const cond = validate('delete');
        if (cond) {
            showLoader(true);
            const response = await del(`/processV2/${idticket_process_v2}`);
            if (response.status === 200) {
                setState(prev => ({ ...prev, data: response?.data ?? [], ticket: { ...prev.ticket, processing_status: response.processingStatus } }));
                showToast(response.message);
            } else {
                console.log('[ProcessV2Detail] destroy error', response);
                helpers.failRequest(response, showToast, signOut)
            }
            showLoader(false);
        }
    }

    const setCompletedWithoutTicket = async () => {
        const { idticket } = state.ticket;

        const cond = validate('complete');
        if (cond) {
            showLoader(true);
            const response = await put(`/processV2/${idticket}/completeWithoutTicket`, { date: state.process.date });
            if (response.status === 200) {
                setState(prev => ({ ...prev, data: response?.data ?? [], ticket: { ...prev.ticket, processing_status: response.processingStatus } }));
                showToast(response.message);
            } else {
                console.log('[ProcessV2Detail] setCompletedWithoutTicket error', response);
                helpers.failRequest(response, showToast, signOut)
            }
            showLoader(false);
        }
    }

    const clear = () => {
        setState(prev => ({
            ...prev,
            process: {
                idticket_process_v2: 0,
                date: dayjs().format('YYYY-MM-DD'),
                processed_qty: 0,
                total_qty: 0,
                pending_qty: 0,
                employee: { id: 0, name: 'Choose an employee' },
                idemployee: 0,
                item: { id: 0, name: 'Choose an item' },
                iditem: 0
            }
        }));
    }

    const getName = params => params?.value?.name ?? 'N/A';
    const getDate = params => dayjs(params.value).format('MM/DD/YYYY');

    const columns = [
        { field: 'employee', headerName: 'Employee', flex: 1, minWidth: 180, valueGetter: getName },
        { field: 'item', headerName: 'Item', flex: 1, minWidth: 130, valueGetter: getName },
        { field: 'date', headerName: 'Date', flex: 1, minWidth: 120, valueGetter: getDate },
        { field: 'processed_qty', headerName: 'Processed qty', flex: 1, minWidth: 150, },
        { field: 'total_qty', headerName: 'Total qty', flex: 1, minWidth: 150 },
        { field: 'pending_qty', headerName: 'Pending qty', flex: 1, minWidth: 150 }
    ];

    if (state.loading) return <Loader />;
    if (!state.permissions.read_attr) return <NotFound />;

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={6} md={4} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Ticket No.'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='text'
                                placeholder='Ticket No.'
                                name='code'
                                value={state.code}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={3} lg={2} className={classes.buttonContainer}>
                    <Button
                        variant='contained'
                        className={classes.button}
                        onClick={getTicket}
                    >
                        {'Search'}
                    </Button>
                </Grid>
                {
                    state.permissions?.update_attr &&
                    <>
                        <Grid item xs={6} md={3} lg={2} className={classes.buttonContainer}>
                            <Button
                                variant='contained'
                                className={classes.discarted}
                                onClick={setDiscarted}
                            >
                                {'Mark as discarted'}
                            </Button>
                        </Grid>
                        <Grid item xs={6} md={3} lg={2} className={classes.buttonContainer}>
                            <Button
                                variant='contained'
                                className={classes.inStorage}
                                onClick={toggleInStorage}
                            >
                                {'Mark in storage'}
                            </Button>
                        </Grid>
                    </>
                }
            </Grid>
            <Grid container>
                <Grid item xs={6} md={4} lg={2}>
                    <FormGroup>
                        <FormLabel>{'Ticket date'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='text'
                                placeholder='Date'
                                value={state.ticket?.date ?? ''}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={4} lg={2}>
                    <FormGroup>
                        <FormLabel>{'Supplier'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='text'
                                placeholder='Supplier'
                                value={state.ticket?.supplier?.name ?? ''}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={4} lg={2}>
                    <FormGroup>
                        <FormLabel>{'Land'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='text'
                                placeholder='Land'
                                value={state.ticket?.land?.name ?? ''}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={4} lg={2}>
                    <FormGroup>
                        <FormLabel>{'Location'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='text'
                                placeholder='Location'
                                value={state.ticket?.location?.name ?? ''}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormGroup>
                        <FormLabel>{'Product'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='text'
                                placeholder='Supplier'
                                value={`${state.ticket?.product_type_ref?.type?.name ?? ''} | ${state.ticket?.product_type_ref?.product?.name ?? ''}`}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={4} lg={2}>
                    <FormGroup>
                        <FormLabel>{'Product qty'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='number'
                                placeholder='Qty'
                                value={state.ticket?.product_qty ?? 0}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={4} lg={2}>
                    <FormGroup>
                        <FormLabel>{'Total qty'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='number'
                                placeholder='Total qty'
                                value={state.ticket?.total_processing_qty ?? 0}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <ProcessingStatusLabel fullInput status={state.ticket?.processing_status ?? 0} />
                </Grid>
            </Grid>

            <Divider className={classes.divider} />

            <Grid container>
                <Grid item xs={6} md={4} lg={2}>
                    <FormGroup>
                        <FormLabel>{'Date'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='date'
                                placeholder='Date'
                                name='date'
                                value={state.process.date}
                                onChange={({ target }) => setState(prev => ({ ...prev, process: { ...prev.process, date: target.value } }))}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Employee'}</FormLabel>
                        <Autocomplete
                            options={state.employees}
                            value={state.process.employee}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(e, value) => handleAutocomplete('employee', value)}
                            renderInput={params => <TextField {...params} placeholder='Employee' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Items'}</FormLabel>
                        <Autocomplete
                            options={state.items}
                            value={state.process.item}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(e, value) => handleAutocomplete('item', value)}
                            renderInput={params => <TextField {...params} placeholder='Item' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={2}>
                    <FormGroup>
                        <FormLabel>{'Processed qty'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='number'
                                name='processed_qty'
                                value={state.process.processed_qty}
                                onChange={handleQty}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={2}>
                    <FormGroup>
                        <FormLabel>{'Total qty'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='number'
                                value={state.process.total_qty}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
            </Grid>

            <Crud
                permissions={state.permissions}
                create={{ create: clear, store }}
                update={update}
                destroy={destroy}
                cancel={clear}
            />

            {
                helpers.hasSpecialPermission() &&
                <div style={{ alignSelf: 'flex-end', marginBottom: 20 }}>
                    <Button
                        variant='contained'
                        className={classes.special_button}
                        onClick={setCompletedWithoutTicket}
                    >
                        {'Complete w/o ticket'}
                    </Button>
                </div>
            }

            <div className={classes.tableContainer}>
                <DataGrid
                    autoHeight
                    pageSize={45}
                    density='compact'
                    columns={columns}
                    rows={state.data}
                    getRowId={row => row.idticket_process_v2}
                    onRowClick={({ row }) => setState(prev => ({ ...prev, process: row }))}
                />
            </div>
        </div>
    );
}

export default ProcessV2Detail;