import React, { useState } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar } from '@material-ui/core';

import Parent from '../../common/parent';
import SelectPage from '../../common/select-page';
import NotFound from '../../common/not-found';
import Settings from '../../common/module-settings';

import Suppliers from './suppliers';
import Items from './items';
import LandOwner from './land_owners';
import Lands from './lands';
import Categories from './categories';
import Types from './types';
import Products from './products';
import ProductTypeRef from './product-type-ref';
import Prices from './prices';
import Stumpage from './stumpage';
import AdminFees from './admin-fees';
import Extras from './extras';
import Tickets from './tickets';
import Checks from './checks';
import Reports from './reports';
import ProductType from './product-type';
import Carrier from './carrier';

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: theme.spacing(3),
        paddingTop: 0
    }
}));

const TicketsParent = () => {

    const classes = useStyles();
    const { path } = useRouteMatch();
    const [module, setModule] = useState(null);

    const update = module => {
        if (module) {
            setModule(module);
        }
    }

    return (
        <Parent module={module} updateModule={update}>
            <main className={classes.content}>
                <Toolbar />
                <Switch>
                    <Route exact path={path} render={props => <SelectPage />} />
                    <Route path={`${path}/ticketCarrier`} render={props => <Carrier {...props} />} />
                    <Route path={`${path}/productType`} render={props => <ProductType {...props} />} />
                    <Route path={`${path}/reports`} render={props => <Reports {...props} />} />
                    <Route path={`${path}/checks`} render={props => <Checks {...props} />} />
                    <Route path={`${path}/tickets`} render={props => <Tickets {...props} />} />
                    <Route path={`${path}/extras`} render={props => <Extras {...props} />} />
                    <Route path={`${path}/adminFees`} render={props => <AdminFees {...props} />} />
                    <Route path={`${path}/stumpage`} render={props => <Stumpage {...props} />} />
                    <Route path={`${path}/prices`} render={props => <Prices {...props} />} />
                    <Route path={`${path}/productTypes`} render={props => <ProductTypeRef {...props} />} />
                    <Route path={`${path}/products`} render={props => <Products {...props} />} />
                    <Route path={`${path}/types`} render={props => <Types {...props} />} />
                    <Route path={`${path}/categories`} render={props => <Categories {...props} />} />
                    <Route path={`${path}/lands`} render={props => <Lands {...props} />} />
                    <Route path={`${path}/landOwners`} render={props => <LandOwner {...props} />} />
                    <Route path={`${path}/items`} render={props => <Items {...props} />} />
                    <Route path={`${path}/suppliers`} render={props => <Suppliers {...props} />} />
                    <Route path={`${path}/settings`} render={props => <Settings {...props} module={module} updateModule={update} />} />
                    <Route path={`${path}/*`} component={NotFound} />
                </Switch>
            </main>
        </Parent>
    );
}

export default TicketsParent;