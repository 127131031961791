import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, Tooltip, IconButton, Icon } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { useLocation, Link } from 'react-router-dom';

import Loader from '../../common/loader';
import Crud from '../../common/crud';
import NotFound from '../../common/not-found';
import ViewTitle from '../../common/view-title';
import Trash from '../../common/trash';

import AuthContext from '../../context';
import { del, get, post, put } from '../../utils/api-services';
import helpers from '../../utils/helpers';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 20
    },
    tableContainer: {
        width: '100%',
    }
}));

const Carrier = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const { pathname } = useLocation();
    const [state, setState] = useState({
        loading: true,
        data: [],
        carrier: {
            idticket_carrier: 0,
            name: ''
        },
        permissions: {}
    });

    useEffect(() => {
        window.document.title = 'Carriers';

        validatePermissions();
    }, []);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/ticketCarrier');
        if (response.status === 200) {
            setState(prev => ({ ...prev, loading: false, data: response?.data ?? [] }));
        } else {
            console.log('[Carrier] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const handleChange = ({ target }) => setState(prev => ({ ...prev, carrier: { ...prev.carrier, [target.name]: target.value } }));

    const validateForm = (type) => {
        const { idticket_carrier, name } = state.carrier;
        const { create_attr, update_attr, delete_attr } = state.permissions;

        switch (type) {
            case 'update':
                if (!update_attr) {
                    showToast('You have no permissions for this action', 'error', 6500);
                    return false;
                }
                const carrier = state.data.find(value => value.idticket_carrier === idticket_carrier);
                if (!carrier) {
                    showToast('Select some carrier', 'error');
                    return false;
                }
                const before = JSON.stringify(carrier);
                const after = JSON.stringify(state.carrier);
                if (before === after) {
                    showToast('Update some field', 'error');
                    return false;
                }
                if (!name.trim()) {
                    showToast('Name field is required', 'error');
                    return false;
                }

                return true;
            case 'store':
                if (!create_attr) {
                    showToast('You have no permissions for this action', 'error', 6500);
                    return false;
                }
                if (!name.trim()) {
                    showToast('Name field is required', 'error');
                    return false;
                }

                return true;
            case 'delete':
                if (!delete_attr) {
                    showToast('You have no permissions for this action', 'error', 6500);
                    return false;
                }
                if (!idticket_carrier) {
                    showToast('Select some carrier', 'error');
                    return false;
                }

                return window.confirm('Are you sure you want to delete this carrier?');
            default:
                break;
        }
    }

    const store = async () => {
        const cond = validateForm('store');

        if (cond) {
            showLoader(true);
            const response = await post('/ticketCarrier/store', state.carrier);
            if (response.status === 200) {
                setState(prev => ({ ...prev, data: response?.data ?? [] }));
                showToast(response.message);
            } else {
                console.log('[Carrier] store error', response);
                helpers.failRequest(response, showToast, signOut);
                showLoader(false);

                return false;
            }
            showLoader(false);
        }

        return cond;
    }

    const update = async () => {
        const { idticket_carrier } = state.carrier;
        const cond = validateForm('update');

        if (cond) {
            showLoader(true);
            const response = await put(`/ticketCarrier/${idticket_carrier}/update`, state.carrier);
            if (response.status === 200) {
                setState(prev => ({ ...prev, data: response?.data ?? [] }));
                showToast(response.message);
            } else {
                console.log('[Carrier] update error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const destroy = async () => {
        const { idticket_carrier } = state.carrier;
        const cond = validateForm('delete');

        if (cond) {
            showLoader(true);
            const response = await del(`/ticketCarrier/${idticket_carrier}`);
            if (response.status === 200) {
                setState(prev => ({ ...prev, data: response?.data ?? [] }));
                showToast(response.message);
            } else {
                console.log('[Carrier] destroy error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const clear = () => {
        setState(prev => ({ ...prev, carrier: { ...prev.carrier, idticket_carrier: 0, name: '' } }));
    }

    const renderCell = ({ row }) => {
        return (
            <Link to={`/history/ticket_carrier/${row.idticket_carrier}`} target='_blank'>
                <Tooltip title='Show history' placement='top'>
                    <IconButton>
                        <Icon>{'history'}</Icon>
                    </IconButton>
                </Tooltip>
            </Link>
        );
    }

    const columns = [
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'history', headerName: 'Actions', flex: 0.3, renderCell }
    ];

    if (state.loading) return <Loader />;
    if (!state.permissions.read_attr) return <NotFound />;

    return (
        <div className={classes.root}>
            <ViewTitle title='Carriers' />
            <div>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <FormGroup>
                            <FormLabel>{'Name'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='text'
                                    placeholder='Name'
                                    name='name'
                                    value={state.carrier.name}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                </Grid>
            </div>

            <Crud
                permissions={state.permissions}
                create={{ create: clear, store }}
                update={update}
                destroy={destroy}
                cancel={clear}
            />

            {
                state.permissions.delete_attr &&
                <Trash table='ticket_carrier' id='idticket_carrier' onRefresh={makeRequest} />
            }

            <div className={classes.tableContainer}>
                <DataGrid
                    rows={state.data}
                    columns={columns}
                    pageSize={45}
                    density='compact'
                    autoHeight
                    getRowId={row => row.idticket_carrier}
                    loading={state.loading}
                    onRowClick={({ row }) => setState(prev => ({ ...prev, carrier: row }))}
                />
            </div>
        </div>
    );
}

export default Carrier;