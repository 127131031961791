import React, { useState, useEffect, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DataGrid } from '@material-ui/data-grid';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

import Loader from '../../common/loader';
import NotFound from '../../common/not-found';

import AuthContext from '../../context';
import { get } from '../../utils/api-services';
import helpers from '../../utils/helpers';
import themeColors from '../../assets/colors';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 20
    },
    tableContainer: {
        width: '100%',
        '& .green': {
            backgroundColor: themeColors.process_status._3
        },
        '& .yellow': {
            backgroundColor: themeColors.process_status._2
        },
        '& .red': {
            backgroundColor: themeColors.process_status._1
        }
    }
}));

const defProduct = { id: 0, name: 'All products' };

const LeaderboardProduct = () => {

    const isMountedRef = useRef(false);
    const productFilterRef = useRef(defProduct);
    const dateFilterRef = useRef(dayjs().format('YYYY-MM-DD'));
    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const { pathname } = useLocation();
    const [state, setState] = useState({
        loading: true,
        products: [],
        filters: {
            product: defProduct,
            date: dayjs().format('YYYY-MM-DD'),
            lastUpdate: dayjs().format('HH:mm')
        },
        data: [],
        permissions: {}
    });

    useEffect(() => {
        window.document.title = 'Leaderboard (Product)';

        validatePermissions();

        const intervalId = setInterval(() => intervalFunction(), 300000);

        return () => {
            clearInterval(intervalId);
        }
    }, []);

    useEffect(() => {
        productFilterRef.current = state.filters.product;
        dateFilterRef.current = state.filters.date;
        intervalFunction();

    }, [state.filters.product, state.filters.date]);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const intervalFunction = async () => {
        if (isMountedRef.current) {
            const newLastUpdate = dayjs().format('HH:mm');
            const currentProduct = productFilterRef.current;
            const currentDate = dateFilterRef.current;

            await search(newLastUpdate, currentProduct, currentDate);
        }
    }

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/productionProductLeaderboard');
        if (response.status === 200) {
            setState(prev => ({ ...prev, loading: false, data: response?.data ?? [], products: response?.products ?? [] }));
            isMountedRef.current = true;
        } else {
            console.log('[LeaderboardProduct] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const search = async (lastUpdate, product, date) => {
        showLoader(true);
        const response = await get('/productionProductLeaderboard/search', { ...state.filters, product, date });
        if (response.status === 200) {
            setState(prev => ({ ...prev, data: response?.data ?? [], filters: { ...prev.filters, lastUpdate } }));
        } else {
            console.log('[LeaderboardProduct] search error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const handleChangeAutocomplete = (key, value) => setState(prev => ({ ...prev, filters: { ...prev.filters, [key]: value } }));
    const handleChangeDate = ({ target }) => setState(prev => ({ ...prev, filters: { ...prev.filters, [target.name]: target.value } }));

    const columns = [
        { field: 'position', headerName: '-', flex: 0.5 },
        { field: 'employee_code', headerName: 'Employee code', flex: 1 },
        { field: 'employee_name', headerName: 'Employee name', flex: 1 },
        { field: 'product_code', headerName: 'Product code', flex: 1 },
        { field: 'product_name', headerName: 'Product name', flex: 1 },
        { field: 'pack_qty', headerName: 'Pack size', flex: 1, type: 'number' },
        { field: 'qty', headerName: 'Qty', flex: 1, type: 'number' },
        { field: 'total_qty', headerName: 'Total', flex: 1, type: 'number' },
        { field: 'productivity', headerName: 'Productivity', flex: 1, align: 'center' }
    ];

    if (state.loading) return <Loader />;
    if (!state.permissions.read_attr) return <NotFound />;

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12} sm={6} lg={6}>
                    <FormGroup>
                        <FormLabel>{'Product'}</FormLabel>
                        <Autocomplete
                            options={state.products}
                            value={state.filters.product}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(e, value) => handleChangeAutocomplete('product', value)}
                            renderInput={params => <TextField {...params} placeholder='Product' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={3}>
                    <FormGroup>
                        <FormLabel>{'Date'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='date'
                                placeholder='Date'
                                name='date'
                                value={state.filters.date}
                                onChange={handleChangeDate}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={3}>
                    <FormGroup>
                        <FormLabel>{'Last update'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='text'
                                value={state.filters.lastUpdate}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
            </Grid>

            <div className={classes.tableContainer}>
                <DataGrid
                    autoHeight
                    disableSelectionOnClick
                    rows={state.data}
                    columns={columns}
                    pageSize={45}
                    density='compact'
                    getRowId={row => `${row['employee_code']}-${row['product_code']}`}
                    getCellClassName={({ field, row }) => {
                        if (field === 'productivity' && row.color === 1) return 'green';
                        if (field === 'productivity' && row.color === 2) return 'yellow';
                        if (field === 'productivity' && row.color === 3) return 'red';

                        return '';
                    }}
                />
            </div>
        </div>
    );
}

export default LeaderboardProduct;