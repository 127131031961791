import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Switch, Route, useLocation, useRouteMatch } from 'react-router-dom';

import ViewTitle from '../../common/view-title';
import Loader from '../../common/loader';
import NotFound from '../../common/not-found';

import LeaderboardMenu from './leaderboard-menu';
import LeaderboardDepartment from './leaderboard-department';

import helpers from '../../utils/helpers';
import LeaderboardProduct from './leaderboard-product';

const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    }
}));

const Leaderboard = () => {

    const classes = useStyles();
    const { pathname } = useLocation();
    const { path } = useRouteMatch();
    const [state, setState] = useState({
        loading: true,
        permissions: {}
    });

    useEffect(() => {
        const validatePermissions = () => {
            const permissions = helpers.getPermissions(pathname);
            setState(prev => ({ ...prev, permissions, loading: false }));
        }

        validatePermissions();
    }, []);

    if (state.loading) {
        return <Loader />;
    }

    if (!state.permissions?.read_attr) {
        return <NotFound />;
    }

    return (
        <div className={classes.root}>
            <ViewTitle sedeFlag title='Leaderboard' />

            <Switch>
                <Route exact path={path}><LeaderboardMenu permissions={state.permissions} /></Route>
                <Route exact path={`${path}/product`}><LeaderboardProduct permissions={state.permissions} /></Route>
                <Route exact path={`${path}/department`}><LeaderboardDepartment permissions={state.permissions} /></Route>
                <Route path={`${path}/*`}><NotFound /></Route>
            </Switch>
        </div>
    );
}

export default Leaderboard;