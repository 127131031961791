import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, FormLabel, FormGroup, TextField, Box, FormControl, FilledInput, Tooltip, IconButton, Icon } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useLocation, Link } from 'react-router-dom';

import Loader from '../../common/loader';
import Crud from '../../common/crud';
import NotFound from '../../common/not-found';
import ViewTitle from '../../common/view-title';
import Trash from '../../common/trash';

import AuthContext from '../../context';
import { del, get, post, put } from '../../utils/api-services';
import helpers from '../../utils/helpers';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 20
    },
    tableContainer: {
        width: '100%'
    }
}));

const ProductTypes = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const { pathname } = useLocation();
    const [state, setState] = useState({
        loading: true,
        products: [],
        types: [],
        ptypes: [],
        ptype: {
            idproduct_type_ref: 0,
            product: {},
            type: {},
            qty: 0
        },
        permissions: {}
    });

    useEffect(() => {
        window.document.title = 'Product divisibility';

        validatePermissions();
    }, []);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/productTypeRef');
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                loading: false,
                ptypes: response?.data ?? [],
                products: response?.products ?? [],
                types: response?.types ?? [],
                ptype: {
                    ...prev.ptype,
                    product: response?.products[0],
                    type: response?.types[0]
                }
            }));
        } else {
            console.log('[ProductTypes] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const validateForm = (option) => {
        const { idproduct_type_ref, product, type } = state.ptype;

        switch (option) {
            case 'update':
                const data = state.ptypes.filter(value => value.idproduct_type_ref === idproduct_type_ref);

                if (data[0]) {
                    const before = JSON.stringify(data[0]);
                    const after = JSON.stringify(state.ptype);
                    if (before !== after) {
                        if (product.id && type.id) {
                            return true;
                        } else {
                            showToast('All fields is required', 'error');
                            return false;
                        }
                    } else {
                        showToast('Update some field', 'error');
                        return false;
                    }
                } else {
                    showToast('Select some data', 'error');
                    return false;
                }

            case 'store':
                if (product.id && type.id) {
                    return true;
                } else {
                    showToast('All fields is required', 'error');
                    return false;
                }

            case 'delete':
                if (idproduct_type_ref === 0) {
                    showToast('Select some data', 'error');
                    return false;
                }
                const cond = window.confirm(`Are you sure you want to delete this data?`);

                return Boolean(idproduct_type_ref && cond);
            default:
                break;
        }
    }

    const clear = () => setState(prev => ({
        ...prev,
        ptype: {
            ...prev.product,
            idproduct_type_ref: 0,
            product: prev.products[0],
            type: prev.types[0],
            qty: 0
        }
    }));

    const store = async () => {
        const cond = validateForm('store');

        if (cond) {
            showLoader(true);
            const response = await post('/productTypeRef/store', state.ptype);
            if (response.status === 200) {
                setState(prev => ({ ...prev, ptypes: response?.data ?? prev.ptypes }));
                showToast(response.message);
            } else {
                console.log('[ProductTypes] store error', response);
                helpers.failRequest(response, showToast, signOut);
                showLoader(false);

                return false;
            }
            showLoader(false);
        }

        return cond;
    }

    const update = async () => {
        const { idproduct_type_ref } = state.ptype;
        const cond = validateForm('update');

        if (cond) {
            showLoader(true);
            const response = await put(`/productTypeRef/${idproduct_type_ref}/update`, state.ptype);
            if (response.status === 200) {
                setState(prev => ({ ...prev, ptypes: response?.data ?? prev.ptypes }));
                showToast(response.message);
            } else {
                console.log('[ProductTypes] update error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const destroy = async () => {
        const { idproduct_type_ref } = state.ptype;
        const cond = validateForm('delete');

        if (cond) {
            showLoader(true);
            const response = await del(`/productTypeRef/${idproduct_type_ref}`);
            if (response.status === 200) {
                setState(prev => ({ ...prev, ptypes: response?.data ?? prev.ptypes }));
                showToast(response.message);
            } else {
                console.log('[ProductTypes] destroy error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const handleAutocomplete = (key, value) => setState(prev => ({ ...prev, ptype: { ...prev.ptype, [key]: value } }));

    const handleChange = ({ target }) => setState(prev => ({ ...prev, ptype: { ...prev.ptype, [target.name]: target.value } }));

    const valueGetter = params => params.value.name;

    const renderCell = ({ row }) => {
        return (
            <Link to={`/history/product_type_ref/${row.idproduct_type_ref}`} target='_blank'>
                <Tooltip title='Show history' placement='top'>
                    <IconButton>
                        <Icon>{'history'}</Icon>
                    </IconButton>
                </Tooltip>
            </Link>
        );
    }

    const columns = [
        { field: 'product', headerName: 'Product', flex: 1, minWidth: 180, valueGetter },
        { field: 'type', headerName: 'Type', flex: 1, minWidth: 180, valueGetter },
        { field: 'qty', headerName: 'Qty', flex: 1, minWidth: 100 },
        { field: 'actions', headerName: 'Actions', flex: 0.5, renderCell }
    ];

    if (state.loading) {
        return <Loader />;
    }
    if (!state.permissions.read_attr) {
        return <NotFound />;
    }

    return (
        <div className={classes.root}>
            <ViewTitle title='Product divisibility' />
            <Grid container>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Product'}</FormLabel>

                        <Autocomplete
                            options={state.products}
                            value={state.ptype.product}
                            getOptionLabel={product => product?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(e, product) => handleAutocomplete('product', product)}
                            renderInput={params => <TextField {...params} placeholder='Product' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Type'}</FormLabel>

                        <Autocomplete
                            options={state.types}
                            value={state.ptype.type}
                            getOptionLabel={type => type?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(e, type) => handleAutocomplete('type', type)}
                            renderInput={params => <TextField {...params} placeholder='Type' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Qty'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='number'
                                placeholder='Qty'
                                name='qty'
                                value={state.ptype.qty}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
            </Grid>

            <Crud
                permissions={state.permissions}
                create={{ create: clear, store }}
                update={update}
                destroy={destroy}
                cancel={clear}
            />

            {
                state.permissions.delete_attr &&
                <Trash table='product_type_ref' id='idproduct_type_ref' onRefresh={makeRequest} />
            }

            <div className={classes.tableContainer}>
                <DataGrid
                    rows={state.ptypes}
                    columns={columns}
                    pageSize={45}
                    density='compact'
                    autoHeight
                    getRowId={row => row.idproduct_type_ref}
                    onRowClick={({ row }) => setState(prev => ({ ...prev, ptype: row }))}
                />
            </div>
        </div>
    );
}

export default ProductTypes;