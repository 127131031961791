import React from 'react';
import { FormLabel, FormGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import themeColors from '../../assets/colors';

const useStyles = makeStyles(() => ({
    label: {
        padding: 15,
        borderRadius: 8,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#E0E0E0'
    }
}));

const ProcessingStatusLabel = ({ fullInput = null, status }) => {
    const classes = useStyles();
    let label = '';

    switch (status) {
        case 1:
            label = `${status}. Pending`;
            break;
        case 2:
            label = `${status}. To complete`;
            break;
        case 3:
            label = `${status}. Completed`;
            break;
        case 4:
            label = `${status}. Discarted`;
            break;
        case 5:
            label = `${status}. In storage`;
            break;
        case 6:
            label = `${status}. No process required`;
            break;
        case 7:
            label = `${status}. Completed w/o ticket`;
            break;
        default:
            label = `${status}. Unknown`;
            break;
    }

    return (
        <FormGroup>
            {
                fullInput &&
                <FormLabel>{'Processing status'}</FormLabel>
            }
            <FormLabel className={classes.label} style={{ backgroundColor: themeColors.process_status[`_${status}`] }}>{label}</FormLabel>
        </FormGroup>
    );

}

export default ProcessingStatusLabel;