import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormLabel, FormGroup, FormControl, FilledInput, TextField, Button, Select, MenuItem } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReactExport from 'react-data-export';
import dayjs from 'dayjs';

import Loader from '../../common/loader';
import DateRange from '../../common/date-range';

import AuthContext from '../../context';
import helpers from '../../utils/helpers';
import { get } from '../../utils/api-services';
import ViewTitle from '../../common/view-title';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    buttonsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        marginBottom: 20
    },
    search: {
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    download: {
        marginRight: 20,
        backgroundColor: '#6E6E6E',
        borderColor: '#6E6E6E',
        '&:hover': {
            backgroundColor: '#6E6E6E',
            borderColor: '#6E6E6E',
            boxShadow: 'none'
        }
    }
}));

const Reports = ({ permissions }) => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const [state, setState] = useState({
        loading: true,
        suppliers: [],
        lands: [],
        locations: [],
        filter: {
            code: '',
            startDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
            endDate: dayjs().format('YYYY-MM-DD'),
            dateRange: `${dayjs().subtract(1, 'day').format('MM/DD/YYYY')} - ${dayjs().format('MM/DD/YYYY')}`,
            supplier: { id: 0, name: 'All suppliers' },
            land: { id: 0, name: 'All lands' },
            location: { id: 0, name: 'All locations' },
            scale_ticket: 0
        },
        data: [],
        rangeModal: {
            open: false,
            range: [
                {
                    startDate: new Date(dayjs().subtract(1, 'day').toDate()),
                    endDate: new Date(),
                    key: 'main'
                }
            ]
        },
        permissions: {}
    });

    useEffect(() => {
        setState(prev => ({ ...prev, permissions }));
    }, [permissions]);

    useEffect(() => {
        window.document.title = 'Reports';

        makeRequest();
    }, []);

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/reports/indexTickets', state.filter);
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                loading: false,
                data: response?.data ?? [],
                suppliers: response?.suppliers ?? [],
                lands: response?.lands ?? [],
                locations: response?.locations ?? []
            }));
        } else {
            console.log('[Reports] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const handleDateChange = item => {
        const { startDate, endDate } = item.main;
        const dateRange = `${dayjs(startDate.toISOString()).format('MM/DD/YYYY')} - ${dayjs(endDate.toISOString()).format('MM/DD/YYYY')}`;
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                dateRange,
                startDate: dayjs(startDate.toISOString()).format('YYYY-MM-DD'),
                endDate: dayjs(endDate.toISOString()).format('YYYY-MM-DD')
            },
            rangeModal: { ...prev.rangeModal, range: [item.main] }
        }));
    }

    const handleModal = () => {
        setState(prev => ({ ...prev, rangeModal: { ...prev.rangeModal, open: !prev.rangeModal.open } }));
    }

    const handleChangeAutocomplete = (key, value) => setState(prev => ({ ...prev, filter: { ...prev.filter, [key]: value } }));

    const search = async () => {
        showLoader(true);
        const response = await get('/reports/searchTickets', state.filter);
        if (response.status === 200) {
            setState(prev => ({ ...prev, data: response?.data ?? [] }));

            if (!response?.data[0]) showToast('There are no data with these filters', 'warning');
        } else {
            console.log('[Reports] search error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    if (state.loading) {
        return <Loader />;
    }

    return (
        <div className={classes.root}>
            <ViewTitle sedeFlag title='Reports' />
            <Grid container>
                <Grid item xs={6} lg={4}>
                    <FormGroup onClick={handleModal}>
                        <FormLabel>{'Date range'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                readOnly
                                autoComplete='off'
                                type='text'
                                placeholder='Date range'
                                name='range'
                                value={state.filter.dateRange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Supplier'}</FormLabel>
                        <Autocomplete
                            disabled={Boolean(state.filter.code.trim())}
                            options={state.suppliers}
                            value={state.filter.supplier}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, value) => handleChangeAutocomplete('supplier', value)}
                            renderInput={params => <TextField {...params} placeholder='Supplier' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Land'}</FormLabel>
                        <Autocomplete
                            disabled={Boolean(state.filter.code.trim())}
                            options={state.lands}
                            value={state.filter.land}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, value) => handleChangeAutocomplete('land', value)}
                            renderInput={params => <TextField {...params} placeholder='Land' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Location'}</FormLabel>
                        <Autocomplete
                            disabled={Boolean(state.filter.code.trim())}
                            options={state.locations}
                            value={state.filter.location}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, value) => handleChangeAutocomplete('location', value)}
                            renderInput={params => <TextField {...params} placeholder='Location' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Ticket báscula/scale'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <Select
                                value={state.filter.scale_ticket}
                                onChange={({ target }) => setState(prev => ({ ...prev, filter: { ...prev.filter, scale_ticket: target.value } }))}
                            >
                                <MenuItem value={0}>{'All tickets'}</MenuItem>
                                <MenuItem value={false}>{'Regular tickets'}</MenuItem>
                                <MenuItem value={true}>{'Scale tickets'}</MenuItem>
                            </Select>
                        </FormControl>
                    </FormGroup>
                </Grid>
            </Grid>

            <div className={classes.buttonsContainer}>
                {
                    state.data.length > 0 &&
                    <ExcelFile
                        filename={`${dayjs().format('YYYY-MM-DD')}-ticket-report`}
                        element={<Button variant='contained' className={classes.download}>{'Download data'}</Button>}
                    >
                        <ExcelSheet data={state.data} name='Ticket report'>
                            <ExcelColumn label='Ticket No.' value='code' />
                            <ExcelColumn label='Date' value='date' />
                            <ExcelColumn label='Process start date' value='process_start_date' />
                            <ExcelColumn label='Status' value='status' />
                            <ExcelColumn label='Supplier code' value='supplier.code' />
                            <ExcelColumn label='Supplier name' value='supplier.name' />
                            <ExcelColumn label='Land code' value='land.code' />
                            <ExcelColumn label='Land name' value='land.name' />
                            <ExcelColumn label='Land owner' value='land.owner.name' />
                            <ExcelColumn label='Sede code (origin)' value='sede_origin.code' />
                            <ExcelColumn label='Sede name (origin)' value='sede_origin.name' />
                            <ExcelColumn label='Location code' value='location.code' />
                            <ExcelColumn label='Location name' value='location.name' />
                            <ExcelColumn label='Item name' value='detail.item.name' />
                            <ExcelColumn label='Product code' value='detail.product.code' />
                            <ExcelColumn label='Product name' value='detail.product.name' />
                            <ExcelColumn label='Product type name' value='detail.product.product_type.name' />
                            <ExcelColumn label='Product type qty' value='detail.qty_product' />
                            <ExcelColumn label='Type name' value='detail.type.name' />
                            <ExcelColumn label='Price' value='detail.final_price' />
                            <ExcelColumn label='Total qty' value='detail.total_qty' />
                            <ExcelColumn label='Subtotal' value='detail.subtotal' />
                            <ExcelColumn label='Admin fee' value='detail.admin_fee' />
                            <ExcelColumn label='Stumpage' value='detail.stumpage' />
                            <ExcelColumn label='Notes' value='detail.note' />
                            <ExcelColumn label='Sede code (destination)' value='sede.code' />
                            <ExcelColumn label='Sede name (destination)' value='sede.name' />
                            <ExcelColumn label='Scale ticket' value='scale_ticket' />
                            <ExcelColumn label='Use carrier' value='use_carrier' />
                            <ExcelColumn label='Carrier name' value='carrier.name' />
                            <ExcelColumn label='Carrier amount' value='carrier_amount' />
                        </ExcelSheet>
                    </ExcelFile>
                }

                <Button
                    variant='contained'
                    className={classes.search}
                    onClick={search}
                >
                    {'Search'}
                </Button>
            </div>

            <DateRange
                open={state.rangeModal.open}
                range={state.rangeModal.range}
                onClose={handleModal}
                onChange={handleDateChange}
                minDate={dayjs('2022-01-01').toDate()}
            />
        </div>
    );
}

export default Reports;