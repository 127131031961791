import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import Loader from '../../common/loader';

import AuthContext from '../../context';
import helpers from '../../utils/helpers';
import { get } from '../../utils/api-services';
import CreateTicket from '../components/create-ticket';

const Logo = require('../../assets/images/logo-full.png').default;

const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly'
    },
    logo: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '@media (min-width: 480px)': {
            width: '90%'
        },
        '@media (min-width: 720px)': {
            width: '60%'
        },
        '@media (min-width: 1000px)': {
            width: '40%'
        }
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '@media (min-width: 480px)': {
            width: '90%'
        },
        '@media (min-width: 720px)': {
            width: '60%'
        },
        '@media (min-width: 1000px)': {
            width: '40%'
        }
    },
    button: {
        width: '30%',
        backgroundColor: '#198754',
        borderColor: '#198754',
        '&:hover': {
            backgroundColor: '#198754',
            borderColor: '#198754',
            boxShadow: 'none',
        },
    },
}));

const TicketsIndex = ({ permissions }) => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const history = useHistory();
    const [showCreate, setShowCreate] = useState(false);
    const [state, setState] = useState({
        loading: true,
        arrays: {},
        permissions: {}
    });

    useEffect(() => {
        setState(prev => ({ ...prev, permissions }));
    }, [permissions]);

    useEffect(() => {
        window.document.title = 'Tickets';

        makeRequest();
    }, []);

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/tickets/create');
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                loading: false,
                arrays: {
                    suppliers: response?.suppliers ?? [],
                    lands: response?.lands ?? [],
                    locations: response?.locations ?? [],
                    carriers: response?.carriers ?? []
                }
            }));
        } else {
            console.log('[TicketsIndex] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    if (state.loading) {
        return <Loader />;
    }

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <img className={classes.logo} src={Logo} alt='logo cfg' />
                <div className={classes.buttonsContainer}>
                    {
                        state.permissions?.create_attr &&
                        <Button
                            variant='contained'
                            className={classes.button}
                            onClick={() => setShowCreate(true)}
                        >
                            {'Create new ticket'}
                        </Button>
                    }
                    <Button
                        variant='contained'
                        className={classes.button}
                        onClick={() => history.push('/ticket/tickets/list')}
                    >
                        {'Show tickets'}
                    </Button>

                </div>
            </div>

            <CreateTicket
                open={showCreate}
                onClose={() => setShowCreate(false)}
                arrays={state.arrays}
            />
        </div>
    );
}

export default TicketsIndex;