import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ReactExport from 'react-data-export';
import dayjs from 'dayjs';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles(() => ({
    search: {
        marginRight: 15,
        backgroundColor: '#6E6E6E',
        borderColor: '#6E6E6E',
        '&:hover': {
            backgroundColor: '#6E6E6E',
            borderColor: '#6E6E6E',
            boxShadow: 'none'
        }
    },
    download: {
        marginRight: 15,
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none'
        }
    }
}));

const DownloadExcel = ({ fileName, sheetName, data, columns, onClick, onReset }) => {
    const classes = useStyles();

    return (
        <div>
            {
                data?.length ?
                    <ExcelFile
                        filename={`${dayjs().format('YYYY-MM-DD')}-${fileName}`}
                        element={<Button variant='contained' className={classes.download} onClick={onReset}>{'Download excel'}</Button>}
                    >
                        <ExcelSheet data={data} name={sheetName}>
                            {
                                columns?.map((item, i) => <ExcelColumn key={`${i}`} label={item.label} value={item.value} />)
                            }
                        </ExcelSheet>
                    </ExcelFile>
                    :
                    <Button variant='contained' className={classes.search} onClick={onClick}>{'Search to download'}</Button>
            }
        </div>
    )
}

export default DownloadExcel;